@import './theme.scss';
@import "./node_modules/@thedmsgroup/one-component-library/dist/assets/scss/widget.scss";

body {
  min-height: auto !important;

  #app {
    // Topbar
    .navbar-custom {
      background-color: $primary;
      height: 70px;
      padding: 0 20px;
      box-sizing: border-box;
      left: 0 !important;

      .container-fluid {
        display: flex;
        align-items: center;

        .topnav-left {
          margin-right: auto;
          width: 100%;

          .topnav-logo {
            padding: 0 10px;
            height: 70px;
            display: inline-flex;
            align-items: center;

            img {
              height: 25px;
            }
          }
          .button-menu-mobile {
              padding: 0 10px;
              width: auto;
          }
        }
        .topnav-right {
          margin-left: auto;
          width: 100%;
        }
      }
    }

    // Left Side menu
    .leftside-menu {
      padding-top: 0 !important;
      top: 70px !important;
      background: #fff !important;
      border-right: 1px solid #E4E6EB;

      .side-nav {
        .side-nav-link,
        .side-nav-item.menuitem-active > a {
          color: #324F6B !important;
          padding: 10px 25px !important;
          display: flex;
          align-items: center;
          height: 55px;
          min-height: 55px !important;
        }
        .side-nav-item .side-nav-link i {
          margin: 0;
          font-size: 1.25rem;
        }
        .side-nav-link span {
          margin-left: 15px;
          margin-top: 4px;
          text-transform: uppercase;
        }
      }
    }

    // Content Page
    .content-page {
      margin-top: 70px;
      padding: 15px;
      min-height: 90vh;
      display: flex;
      flex-direction: column;
      margin-left: 70px !important;
    }

    // Loader
    .bouncing-loader > div {
      background: $primary;
    }

    // Footer
    .footer {
      position: static;
      margin-top: auto;
    }

    // Error
    .text-error {
      text-shadow: none;
    }
    .icon-error {
      color: $danger;
      font-size: 4rem;
    }
    .error-logo {
      height: 60px;
    }
  }
  &[data-leftbar-compact-mode=condensed] {
    .side-nav-item:hover .side-nav-link {
      background: transparent !important;
      color: #324F6B !important;

      span {
        display: none !important;
      }
    }
  }
}
